import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BasicDetails from './ProfileStepper/BasicDetails'
import Protection from './ProfileStepper/Protection'
import Goals from './ProfileStepper/Goals'
import Cashflow from './ProfileStepper/Cashflow'
import Investments from './ProfileStepper/Investments'
import Compeleted from '../components/wizard/Compeleted'
import Header from '../components/layout/Header'
import axioInstance from '../components/axiosInstance'
import { Encryption } from '../components/global'
export default function ProfileStepper() {

    // eslint-disable-next-line
    const [members, setMembers] = useState([]); // not used
    const [lifeStage, setLifeStage] = useState(null);
    const [childCount, setChildCount] = useState(1);


    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.post(`profile/summary`, { profile_id: profile_id }).then(
            (response, data) => {
                if (response.data.status === 100) {

                    if (response.data.self_data.stepper === "1") {
                        setSteps(1);
                    } else {
                        setSteps(6);

                    }
                    sessionStorage.setItem('profile_details_id', Encryption(response.data.self_data.profile_details_id));
                    sessionStorage.setItem('current_age', response.data.self_data.current_age);
                    sessionStorage.setItem('self_dob', response.data.self_data.dob);

                    if (response.data.self_data.lifeStage !== null) {

                        setLifeStage(response.data.self_data.lifeStage);

                        setChildCount((response.data.self_data.lifeStage === "single" || response.data.self_data.lifeStage === "married") ? 0 : (response.data.child_cnt !== 0 ? response.data.child_cnt : 1));
                    }
                }
            });

        /*axioInstance.post(`profile-details/getfamily`, { profile_id: profile_id }
        ).then(
            (response, data) => {
                setMembers(response.data);
            });*/

    }, []);

    let navigate = useNavigate();

    const loggedInUser = sessionStorage.getItem("token");
    if (loggedInUser === null) {
        navigate("/sign-in");
    }

    const [steps, setSteps] = useState(6);

    const StepChange = (steps) => {
        setSteps(steps);
    }

    return (

        <Fragment>
            <Header steps={steps} />
            <div className="innercontainer mb40">


                {steps === 1 && (<BasicDetails StepChange={StepChange} steps={steps} childCount={childCount} setChildCount={setChildCount} lifeStage={lifeStage} setLifeStage={setLifeStage} />)}
                {steps === 2 && (<Protection StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 3 && (<Goals StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 4 && (<Cashflow StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 5 && (<Investments StepChange={StepChange} steps={steps} lifeStage={lifeStage} />)}
                {steps === 6 && (<Compeleted StepChange={StepChange} steps={steps} />)}

            </div>
        </Fragment>


    )
}