import axioInstance from '../axiosInstance';
import React, { Fragment } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import '../../css/processstep.css';
import SideImg from '../../img/image261.png';

import { stepData } from '../global';
export default function Compeleted({ StepChange, steps }) {

    const editStep = (stepId) => {

        StepChange(stepId);
    }


    const [activeStep, setActiveStep] = useState();
    useEffect(() => {

        console.log('profile data completed');
        axioInstance.post(`profile/profile-data`, { profile_id: sessionStorage.getItem('profile_id'.trim()) }).then(
            function (response, data) {
                setActiveStep(response.data[0].stepper);
                sessionStorage.setItem('stepper', response.data[0].stepper);
            }
        )
    }, [activeStep]);

    return (
        <Fragment>

            <div className="mainwrapper">

                <div className="form_title">
                    <div className="mt30 font20 fw600">All Done</div>
                    <div className="font15 color495">Questions to know basic details</div>
                </div>


                <div className="row formWpr mt25">
                    <div className="col-lg-8 formouter">


                        <div className="mob_left_right p40">
                            <ul className="processstep_list">

                                {stepData.map((data, key) => {
                                    return (
                                        <li key={key}>
                                            <div className="processstep_list_left fw500 font14">
                                                <span className={data.icon + ' mr15'} />
                                                {data.name}
                                            </div>
                                            <div className="processstep_list_right">




                                                {

                                                    data.step > activeStep ? "" : data.step < activeStep ? (
                                                        <>
                                                            <span className="greentick" style={{ "visibility": "visible" }}></span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="previewtab font12 fw600 color212 mr25 resumeBtn" onClick={() => { editStep(data.step) }} style={{ "visibility": "visible" }}> Resume </div>

                                                            <span className="greentick" style={data.status === true ? { "visibility": "visible" } : { "visibility": "hidden" }}></span>
                                                        </>
                                                    )
                                                }



                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            {

                                activeStep < 3 ? (<></>) : (<>

                                    <div className="font20 fw500 color212 mt100">
                                        Your plan is ready! Lets start tweaking it now.
                                    </div>
                                    <div className="mt8 font16 fw500 color495">Appreciate your patience on this input process. Thank you.</div>
                                    <a href="/dashboard" className="btn bluebtn font13 mt25">Get Started</a>

                                </>)
                            }



                        </div>

                    </div>

                    <div className="col-lg-4 qouteouter d-none d-md-block">
                        <div className="qoutetext font18 fw500 color212 pl45 pr45">
                            <div className="qoutesign">
                                &#8220;
                            </div>
                            Don’t worry about enclosing PAN details to finnovate. Your details will be secure and encrypted.
                        </div>


                        <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                    </div>
                </div>


            </div>
        </Fragment>
    )
}
